import en from './en';

const languages = [ en ];

function parseTranslationObject(main, obj) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      parseTranslationObject(main, obj[key]);
      return;
    }

    if (obj[key].indexOf('$$') >= 0) {
      const ref = obj[key].replace('$$', '').split('.');
      let treePart = {};
      for (let i = 0; i < ref.length; i++) {
        treePart = treePart[ref[i]] || main[ref[i]];
      }
      obj[key] = `${treePart}`;
    }
  });
}

languages.map(lang => parseTranslationObject(lang, lang));

export default languages[0];
